var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "frmBlock__layout"
  }, _vm._l(_vm.option.componentOption.properties[0].elements.options, function (value, index) {
    return _c('label', {
      key: index,
      staticClass: "frmBlock__label"
    }, [_c('input', {
      attrs: {
        "type": "checkbox"
      },
      domProps: {
        "value": value,
        "checked": _vm.checked.includes(value)
      },
      on: {
        "input": function input($event) {
          return _vm.updateOption($event);
        }
      }
    }), _c('span', [_vm._v(_vm._s(value))])]);
  }), 0)]);
}
var staticRenderFns = []

export { render, staticRenderFns }