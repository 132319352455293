<template>
  <div>
    <div class="frmBlock__layout">
      <label
        class="frmBlock__label"
        v-for="(value, index) in option.componentOption.properties[0].elements
          .options"
        :key="index"
      >
        <input
          type="checkbox"
          :value="value"
          @input="updateOption($event)"
          :checked="checked.includes(value)"
        />
        <span>{{ value }}</span>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    option: {
      type: Object
    }
  },
  data() {
    return {
      checked: []
    };
  },
  computed: {
    initialValue() {
      return this.$route.path === "/option"
        ? this.option.componentOption.properties[0].initialValue
        : this.option.value.data;
    }
  },
  mounted() {
    if (this.initialValue) this.checked = this.initialValue;
    if (this.$route.path === "/option") {
      this.updateProperty(this.checked);
    }
  },
  methods: {
    // 프로퍼티 업데이트 (파트너)
    updateProperty(value) {
      let modified = {
        id: this.option.componentOption.id,
        properties: [
          {
            id: this.option.componentOption.properties[0].id,
            initialValue: value
          }
        ]
      };
      this.$emit("updateProperty", this.option.componentOption.key, modified);
    },
    // 옵션 Update
    updateOption(e) {
      if (this.checked.includes(e.target.value)) {
        const idx = this.checked.indexOf(e.target.value);
        this.checked.splice(idx, 1);
      } else {
        this.checked.push(e.target.value);
      }

      this.$emit(
        "updateOption",
        this.option.componentOption.key,
        this.option.componentOption.properties[0].key,
        this.checked
      );

      if (this.$route.path === "/option") {
        this.updateProperty(this.checked);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.frmBlock__layout {
  margin-bottom: -20px;
  flex-wrap: wrap;

  .frmBlock__label {
    margin-right: 30px;
    margin-bottom: 20px;
  }
}
</style>
